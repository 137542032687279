import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "@material-ui/core/styles";
import {
	BarChart,
	CartesianGrid,
	Bar,
	XAxis,
	YAxis,
	Label,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from "recharts";
import { startOfHour, parseISO, format } from "date-fns";

import { i18n } from "../../translate/i18n";

import Title from "./Title";
import useTickets from "../../hooks/useTickets";

const Chart = () => {
	const theme = useTheme();

	const date = useRef(new Date().toISOString());
	const { tickets } = useTickets({ date: date.current });

	const [chartData, setChartData] = useState([
		{ time: "01:00", Atendendo: 0, Aguardando: 0, Encerrados: 0, Todos: 0},
		{ time: "02:00", Atendendo: 0, Aguardando: 0, Encerrados: 0, Todos: 0},
		{ time: "03:00", Atendendo: 0, Aguardando: 0, Encerrados: 0, Todos: 0},
		{ time: "04:00", Atendendo: 0, Aguardando: 0, Encerrados: 0, Todos: 0},
		{ time: "05:00", Atendendo: 0, Aguardando: 0, Encerrados: 0, Todos: 0},
		{ time: "06:00", Atendendo: 0, Aguardando: 0, Encerrados: 0, Todos: 0},
		{ time: "07:00", Atendendo: 0, Aguardando: 0, Encerrados: 0, Todos: 0},
		{ time: "08:00", Atendendo: 0, Aguardando: 0, Encerrados: 0, Todos: 0},
		{ time: "09:00", Atendendo: 0, Aguardando: 0, Encerrados: 0, Todos: 0},
		{ time: "10:00", Atendendo: 0, Aguardando: 0, Encerrados: 0, Todos: 0},
		{ time: "11:00", Atendendo: 0, Aguardando: 0, Encerrados: 0, Todos: 0},
		{ time: "12:00", Atendendo: 0, Aguardando: 0, Encerrados: 0, Todos: 0},
		{ time: "13:00", Atendendo: 0, Aguardando: 0, Encerrados: 0, Todos: 0},
		{ time: "14:00", Atendendo: 0, Aguardando: 0, Encerrados: 0, Todos: 0},
		{ time: "15:00", Atendendo: 0, Aguardando: 0, Encerrados: 0, Todos: 0},
		{ time: "16:00", Atendendo: 0, Aguardando: 0, Encerrados: 0, Todos: 0},
		{ time: "17:00", Atendendo: 0, Aguardando: 0, Encerrados: 0, Todos: 0},
		{ time: "18:00", Atendendo: 0, Aguardando: 0, Encerrados: 0, Todos: 0},
		{ time: "19:00", Atendendo: 0, Aguardando: 0, Encerrados: 0, Todos: 0},
		{ time: "20:00", Atendendo: 0, Aguardando: 0, Encerrados: 0, Todos: 0},
		{ time: "21:00", Atendendo: 0, Aguardando: 0, Encerrados: 0, Todos: 0},
		{ time: "22:00", Atendendo: 0, Aguardando: 0, Encerrados: 0, Todos: 0},
		{ time: "23:00", Atendendo: 0, Aguardando: 0, Encerrados: 0, Todos: 0},
		{ time: "00:00", Atendendo: 0, Aguardando: 0, Encerrados: 0, Todos: 0},
	]);

	useEffect(() => {
		setChartData(prevState => {
			let aux = [...prevState];

			console.log(tickets)

			aux.forEach(a => {
				tickets.forEach(ticket => {
					if ( ticket.status === "pending" ) {
						format(startOfHour(parseISO(ticket.createdAt)), "HH:mm") === a.time &&
						a.Aguardando++;
					}
					if ( ticket.status === "open" ) {
						format(startOfHour(parseISO(ticket.createdAt)), "HH:mm") === a.time &&
						a.Atendendo++;
					}
					if ( ticket.status === "closed") {
						format(startOfHour(parseISO(ticket.createdAt)), "HH:mm") === a.time &&
						a.Encerrados++;
					}
					format(startOfHour(parseISO(ticket.createdAt)), "HH:mm") === a.time &&
					a.Todos++;
				});
			});

			return aux;
		});
	}, [tickets]);

	return (
		<React.Fragment>
		<Title>{`${i18n.t("dashboard.charts.perDay.title")}${
			tickets.length
		}`}</Title>
	<ResponsiveContainer>
	<BarChart
	data={chartData}
	barSize={40}
	width={730}
	height={250}
	margin={{
		top: 16,
			right: 16,
			bottom: 0,
			left: 24,
	}}
>
<CartesianGrid strokeDasharray="3 3" />
		<XAxis dataKey="time" stroke={theme.palette.text.secondary} />
	<YAxis
	type="number"
	allowDecimals={false}
	stroke={theme.palette.text.secondary}
		>
		<Label
	angle={270}
	position="left"
	style={{ textAnchor: "middle", fill: theme.palette.text.primary }}
>
	Tickets
	</Label>
	</YAxis>
	<Tooltip />
	<Legend />
	<Bar dataKey="Atendendo" fill="#2e7d32" />
		<Bar dataKey="Aguardando" fill="#ed6c02" />
		<Bar dataKey="Encerrados" fill="#d32f2f" />
		<Bar dataKey="Todos" fill={theme.palette.primary.main}/>
	</BarChart>
	</ResponsiveContainer>
	</React.Fragment>
);
};

export default Chart;
