import React, { useContext, useEffect, useRef, useState, SyntheticEvent } from "react";
import openSocket from "../../services/socket-io";
import api from "../../services/api";

import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Badge from '@mui/material/Badge';
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import NewTicketModal from "../NewTicketModal";
import TicketsList from "../TicketsList";
import TabPanel from "../TabPanel";

// import useTickets from "../../hooks/useTickets";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import TicketsQueueSelect from "../TicketsQueueSelect";
// import { Button } from "@material-ui/core";
import { Button } from 'reactstrap';
import { TagsFilter } from "../TagsFilter";

const useStyles = makeStyles((theme) => ({
    ticketsWrapper: {
        position: "relative",
        display: "flex",
        height: "100%",
        flexDirection: "column",
        overflow: "hidden",
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    },

    tabsHeader: {
        flex: "none",
        backgroundColor: "#eee",
    },

    settingsIcon: {
        alignSelf: "center",
        marginLeft: "auto",
        padding: 8,
    },

    tab: {
        minWidth: 120,
        width: 120,
    },

    ticketOptionsBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        background: "#fafafa",
        padding: theme.spacing(1),
    },

    serachInputWrapper: {
        flex: 1,
        background: "#fff",
        display: "flex",
        borderRadius: 40,
        padding: 4,
        marginRight: theme.spacing(1),
    },

    searchIcon: {
        color: "grey",
        marginLeft: 6,
        marginRight: 6,
        alignSelf: "center",
    },

    searchInput: {
        flex: 1,
        border: "none",
        borderRadius: 30,
    },
}));


const TicketsManager = () => {
    const classes = useStyles();
    const history = useHistory();

    const [searchParam, setSearchParam] = useState("");
    const [tab, setTab] = useState("open");
    const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
    const [showAllTickets, setShowAllTickets] = useState(false);
    // const [showIsGroup, setShowIsGroup] = useState(false);
    const searchInputRef = useRef();
    const { user } = useContext(AuthContext);

    const [openCount, setOpenCount] = useState(0);
    const [pendingCount, setPendingCount] = useState(0);
    const [groupCount, setGroupCount] = useState(0);

    const userQueueIds = user.queues.map((q) => q.id);
    const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);
    const [selectedTags, setSelectedTags] = useState([]);
    const [value, setValue] = useState("tickets");


    useEffect(() => {

        const Tkts = async () => {
            try {
                const {data} = await api.get("/tickets", {
                    params: {showAll: "true", queueIds: JSON.stringify(selectedQueueIds)},
                });


                // console.log("Dados: ",data);

                let tickets = data.tickets;
                let open = tickets.filter((g) => (g.isGroup === false && g.status === "open"));
                let pend = tickets.filter((g) => (g.status === "pending"));
                let grp = tickets.filter((g) => (g.isGroup === true && g.status === "open"));

                setOpenCount(open.length);
                setPendingCount(pend.length);
                setGroupCount(grp.length);

            } catch (err) {

                console.log("Error: ", err);
                // setLoading(false);
                // toastError(err);
            }
        }

        Tkts();

    }, [selectedQueueIds]);


    const fetchTickets = async () => {
        try {
            const {data} = await api.get("/tickets", {
                params: {showAll: "true", queueIds: JSON.stringify(selectedQueueIds)},
            });


            // console.log("Dados: ",data);

            let tickets = data.tickets;
            let open = tickets.filter((g) => (g.isGroup === false && g.status === "open"));
            let pend = tickets.filter((g) => (g.status === "pending"));
            let grp = tickets.filter((g) => (g.isGroup === true && g.status === "open"));

            setOpenCount(open.length);
            setPendingCount(pend.length);
            setGroupCount(grp.length);

        } catch (err) {

            console.log("Error: ", err);
            // setLoading(false);
            // toastError(err);
        }
    }


    useEffect(() => {

        const socket = openSocket();

        socket.on("connect", () => {
            socket.emit("joinNotification");
        });

        socket.on("appMessage", (data) => {

            fetchTickets();
            console.log("Msg: ", data.length);
        });

        socket.on("ticket", (data) => {

            if(data.action === 'update'){
                // console.log("Ticket: ", data);

                if(data.ticket.status === 'open'){
                    if(data.ticket.isGroup === false) {
                        // console.log("Individual: ", data.ticket.status);
                        setValue('tickets');
                    }
                    if(data.ticket.isGroup === true){
                        // console.log("Grupo: ", data.ticket.status);
                        setValue('grupos');
                    }
                }

            }
            fetchTickets();

        });
        return () => {
            socket.disconnect();
        };

    }, [fetchTickets, setValue]);

    useEffect(() => {
        if (tab === "search") {
            searchInputRef.current.focus();
        }
    }, [tab]);

    let searchTimeout;

    const handleChangeTabs = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleSearch = (e) => {
        const searchedTerm = e.target.value.toLowerCase();

        clearTimeout(searchTimeout);

        if (searchedTerm === "") {
            setSearchParam(searchedTerm);
            // setTab("open");
            return;
        }

        searchTimeout = setTimeout(() => {
            setSearchParam(searchedTerm);
        }, 500);
    };

    const handleChangeTab = (e, newValue) => {
        setTab(newValue);
    };

    const handleCloseOrOpenTicket = (ticket) => {
        setNewTicketModalOpen(false);
        if (ticket !== undefined && ticket.uuid !== undefined) {
            history.push(`/tickets/${ticket.uuid}`);
        }
    }

    const handleSelectedTags = (selecteds) => {
        const tags = selecteds.map(t => t.id);
        setSelectedTags(tags);
    }

    return (
        <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
        <NewTicketModal
    modalOpen={newTicketModalOpen}
    onClose={(ticket) => {
        handleCloseOrOpenTicket(ticket);
    }}
    />
    <Paper elevation={0} square className={classes.tabsHeader}>
        <Tabs
    value={tab}
    onChange={handleChangeTab}
    variant="fullWidth"
    indicatorColor="primary"
    textColor="primary"
    aria-label="icon label tabs example"
        >
        <Tab
    value={"open"}
    icon={<MoveToInboxIcon />}
    label={i18n.t("tickets.tabs.open.title")}
    classes={{ root: classes.tab }}
    />
    <Tab
    value={"closed"}
    icon={<CheckBoxIcon />}
    label={i18n.t("tickets.tabs.closed.title")}
    classes={{ root: classes.tab }}
    />
    <Tab
    value={"search"}
    icon={<SearchIcon />}
    label={i18n.t("tickets.tabs.search.title")}
    classes={{ root: classes.tab }}
    />
    </Tabs>
    </Paper>
    <Paper square elevation={0} className={classes.ticketOptionsBox}>
    {tab === "search" ? (
        <div className={classes.serachInputWrapper}>
        <SearchIcon className={classes.searchIcon} />
    <InputBase
    className={classes.searchInput}
    inputRef={searchInputRef}
    placeholder={i18n.t("tickets.search.placeholder")}
    type="search"
    onChange={handleSearch}
    />
    </div>
) : (
    <>
    <Button
    outline
    color="primary"
    size="sm"
    onClick={() => setNewTicketModalOpen(true)}
>
    {i18n.t("ticketsManager.buttons.newTicket")}
</Button>
    <Can
    role={user.profile}
    perform="tickets-manager:showall"
    yes={() => (
    <FormControlLabel
    label={i18n.t("tickets.buttons.showAll")}
    labelPlacement="start"
    control={
        <Switch
    size="small"
    checked={showAllTickets}
    onChange={() =>
    setShowAllTickets((prevState) => !prevState)
}
    name="showAllTickets"
    color="primary"
        />
}
    />
)}
    />
    </>
)}
<TicketsQueueSelect
    style={{ marginLeft: 6 }}
    selectedQueueIds={selectedQueueIds}
    userQueues={user?.queues}
    onChange={(values) => setSelectedQueueIds(values)}
    />
    </Paper>

    <Paper elevation={3} square className={classes.tabsHeader}>
    {tab === "search" || tab === "closed" ? (
        <>
        </>
) : (
    <>
    <Tabs
    value={value}
    onChange={handleChangeTabs}
    aria-label="basic tabs example"
    indicatorColor="primary"
        >
        <Tab icon={<Badge color="success" badgeContent={openCount}> Abertos &nbsp; </Badge>} value={"tickets"} />
    <Tab icon={<Badge color="warning" badgeContent={pendingCount}> {i18n.t("ticketsList.pendingHeader")} &nbsp;&nbsp;&nbsp; </Badge>} value={"pending"} />
    <Tab icon={<Badge color="info" badgeContent={groupCount}> Grupos &nbsp; </Badge>} value={"grupos"} />
    </Tabs>
    </>
)}
</Paper>
    <TabPanel value={tab} name="open" className={classes.ticketsWrapper}>
        <TabPanel value={value} name="tickets" className={classes.ticketsWrapper}>
        <TicketsList
    status="open"
    showAll={showAllTickets}
    isGroup={false}
    chatLocal={false}
    selectedQueueIds={selectedQueueIds}
    />
    </TabPanel>
    <TabPanel value={value} name="pending" className={classes.ticketsWrapper}>
        <TicketsList
    status="pending"
    showAll={true}
    isGroup="all"
    chatLocal={false}
    selectedQueueIds={selectedQueueIds}
    />
    </TabPanel>
    <TabPanel value={value} name="grupos" className={classes.ticketsWrapper}>
        <TicketsList
    status="open"
    showAll={true}
    isGroup={true}
    chatLocal={false}
    selectedQueueIds={selectedQueueIds}
    />
    </TabPanel>
    </TabPanel>
    <TabPanel value={tab} name="closed" className={classes.ticketsWrapper}>
        <TicketsList
    status="closed"
    showAll={true}
    selectedQueueIds={selectedQueueIds}
    />
    </TabPanel>
    <TabPanel value={tab} name="search" className={classes.ticketsWrapper}>
        <TagsFilter onFiltered={handleSelectedTags} />
    <TicketsList
    searchParam={searchParam}
    tags={selectedTags}
    showAll={true}
    isGroup="all"
    selectedQueueIds={selectedQueueIds}
    />
    </TabPanel>
    </Paper>
);
};

export default TicketsManager;
