import {useState, useEffect} from "react";
import toastError from "../../errors/toastError";

import api from "../../services/api";

const useTickets = ({
                        searchParam,
                        pageNumber,
                        tags,
                        status,
                        date,
                        updatedAt,
                        showAll,
                        isGroup,
                        queueIds,
                        withUnreadMessages,
                    }) => {
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [count, setCount] = useState(0);


    const closeTicket = async (ticket) => {

        const message = {
            read: 1,
            fromMe: true,
            mediaUrl: "",
            body: "Por não haver interação, estamos encerrando seu atendimento\n\n",
        };
        try {

            await api.post(`/messages/${ticket.id}`, message);

        } catch (err) {
            toastError(err);
        }
        await api.put(`/tickets/${ticket.id}`, {
            status: "closed",
            userId: ticket.userId || null,
        })

        await api.delete(`/tickets/${ticket.id}`);

    }

    useEffect(() => {
        setLoading(true);
        // const delayDebounceFn = setTimeout(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchTickets = async () => {
                try {
                    const {data} = await api.get("/tickets", {
                        params: {
                            searchParam,
                            tags,
                            pageNumber,
                            status,
                            date,
                            updatedAt,
                            showAll,
                            isGroup,
                            queueIds,
                            withUnreadMessages,
                        },
                    });

                    // if(isGroup === true) {
                    //     dataTK = data.tickets.filter((g) => (g.isGroup === true));
                    // } else if(isGroup === false) {
                    //     dataTK = data.tickets.filter((g) => (g.isGroup === false ));
                    // } else {
                    //     dataTK = ;
                    // }
                    setTickets(data.tickets);
                    // console.log("isGroup Aqui:", dataTK);

                    let minFecharAutomaticamente = process.env.REACT_APP_MINUTES_CLOSE_TICKETS_AUTO

                    // console.log("Tempo:", minFecharAutomaticamente);


                    if (status === "pending" && minFecharAutomaticamente && minFecharAutomaticamente !== "" &&
                        minFecharAutomaticamente !== "0" && Number(minFecharAutomaticamente) > 0) {

                        let dataLimite = new Date()
                        dataLimite.setMinutes(dataLimite.getMinutes() - Number(minFecharAutomaticamente))

                        data.tickets.forEach(ticket => {
                            if (ticket.status !== "closed") {
                                let dataUltimaInteracaoChamado = new Date(ticket.updatedAt)
                                if (dataUltimaInteracaoChamado < dataLimite)
                                    closeTicket(ticket)
                            }
                        })
                    }


                    setHasMore(data.hasMore);
                    setCount(data.count)
                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    toastError(err);
                }
            };

            fetchTickets();


        }, 1000);
        return () => clearTimeout(delayDebounceFn);
    }, [
        searchParam,
        pageNumber,
        status,
        tags,
        date,
        updatedAt,
        showAll,
        isGroup,
        queueIds,
        withUnreadMessages,
    ]);

    return {tickets, loading, hasMore, count};
};

export default useTickets;
