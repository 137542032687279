// import { toast } from "react-toastify";
import { i18n } from "../translate/i18n";
import Swal from 'sweetalert2'

const toastError = err => {
	const errorMsg = err.response?.data?.message || err.response.data.error;
	if (errorMsg) {
		if (i18n.exists(`backendErrors.${errorMsg}`)) {
			Swal.fire({
				title: 'Ops!',
				text: i18n.t(`backendErrors.${errorMsg}`),
				icon: 'error',
				showConfirmButton: false,
				timer: 3000
			})
		} else {
			Swal.fire({
				title: 'Ops!',
				text: errorMsg,
				icon: 'error',
				showConfirmButton: false,
				timer: 3000
			})
		}
	} else {
		Swal.fire({
			title: 'Ops!',
			text: 'Um erro ocorreu!',
			icon: 'error',
			showConfirmButton: false,
			timer: 3000
		})
	}
};

export default toastError;
