import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { AddCircleOutline, ChatBubbleOutlineOutlined, LibraryBooks } from "@mui/icons-material";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Divider from "@mui/material/Divider";
import { Badge } from "@mui/material";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import EventIcon from '@mui/icons-material/Event';
import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";

function ListItemLink(props) {
  const { icon, primary, to, className } = props;
    const location = useLocation();

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <li>
      <ListItem button component={renderLink} className={className} selected={location.pathname === to ? true : false}>
        {location.pathname === to ? (
            <>
            <span
                style={{
                    backgroundColor: "rgba(8,63,197,0.61)",
                    flex: "none",
                    width: "8px",
                    height: "100%",
                    position: "absolute",
                    top: "0%",
                    left: "0%"
                }}
            >
            </span>
        </>
        ) : ( <> </> )}
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

const MainListItems = (props) => {
  const { drawerClose } = props;
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user } = useContext(AuthContext);
  const [connectionWarning, setConnectionWarning] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return (
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          );
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);


  return (
    <div onClick={drawerClose}>
      <ListItemLink
        to="/tickets"
        primary={i18n.t("mainDrawer.listItems.tickets")}
        icon={<WhatsAppIcon />}
      />

      <ListItemLink
        to="/contacts"
        primary={i18n.t("mainDrawer.listItems.contacts")}
        icon={<ContactPhoneOutlinedIcon />}
      />
      <ListItemLink
        to="/quickAnswers"
        primary={i18n.t("mainDrawer.listItems.quickAnswers")}
        icon={<QuestionAnswerOutlinedIcon />}
      />
      <ListItemLink
        to="/schedules"
        primary={i18n.t("mainDrawer.listItems.schedules")}
        icon={<EventIcon />}
      />
      <ListItemLink
        to="/tags"
        primary={i18n.t("mainDrawer.listItems.tags")}
        icon={<LocalOfferIcon />}
      />

      <Can
        role={user.profile}
        perform="drawer-supervisor-items:view"
        yes={() => (
            <>
                <ListItemLink
                    to="/"
                    primary="Dashboard"
                    icon={<DashboardOutlinedIcon />}
                />
                <ListItemLink
                    to="/connections"
                    primary={i18n.t("mainDrawer.listItems.connections")}
                    icon={
                        <Badge
                            overlap="rectangular"
                            badgeContent={connectionWarning ? "!" : 0}
                            color="error"
                        >
                            <SyncAltIcon />
                        </Badge>
                    }
                />
                <Divider />
                <ListSubheader inset>
                    Gerenciar Campanhas
                </ListSubheader>

                <ListItemLink
                    to="/BulkMessage"
                    primary="Campanhas"
                    icon={<ChatBubbleOutlineOutlined />}
                />
                <ListItemLink
                    to="/ShippingReport"
                    primary="Relatório Envios"
                    icon={<LibraryBooks />}
                />
                <ListItemLink
                    to="/SettingsMessage"
                    primary="Config. Envios"
                    icon={<AddCircleOutline />}
                />

                <Divider />
                <ListSubheader inset>
                    {i18n.t("mainDrawer.listItems.administration")}
                </ListSubheader>
                <ListItemLink
                    to="/users"
                    primary={i18n.t("mainDrawer.listItems.users")}
                    icon={<PeopleAltOutlinedIcon />}
                />
                <ListItemLink
                    to="/queues"
                    primary={i18n.t("mainDrawer.listItems.queues")}
                    icon={<AccountTreeOutlinedIcon />}
                />
                <ListItemLink
                    to="/settings"
                    primary={i18n.t("mainDrawer.listItems.settings")}
                    icon={<SettingsOutlinedIcon />}
                />
            </>
        )}
      />


    <Can
        role={user.profile}
        perform="drawer-admin-items:view"
        yes={() => (
          <>
          <Divider />
            <ListSubheader inset>
              {i18n.t("mainDrawer.listItems.api")}
            </ListSubheader>
       
           <ListItemLink
              to="/tokens"
              primary={i18n.t("mainDrawer.listItems.tokens")}
              icon={<VpnKeyIcon />}
            />

            <ListItemLink
              to="/swagger"
              primary="Docs Swagger"
              icon={<MenuBookIcon />}
            />

              <ListItemLink
              to="/docs"
              primary={i18n.t("mainDrawer.listItems.docs")}
              icon={<MenuBookIcon />}
            />

          </>
        )}
      />
      </div>
  );
};

export default MainListItems;
