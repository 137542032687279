import React, { useContext } from "react";

import Paper from "@mui/material/Paper"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@mui/material/Typography";

import SupportAgentTwoToneIcon from '@mui/icons-material/SupportAgentTwoTone';
import ScheduleTwoToneIcon from '@mui/icons-material/ScheduleTwoTone';
import ThumbUpTwoToneIcon from '@mui/icons-material/ThumbUpTwoTone';
import PlaylistAddCheckTwoToneIcon from '@mui/icons-material/PlaylistAddCheckTwoTone';
// import { Card } from "@mui/material"

import useTickets from "../../hooks/useTickets"

import { AuthContext } from "../../context/Auth/AuthContext";

import Chart from "./Chart";
// import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	fixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 540,
		align: "center",
	},
}));


const Dashboard = () => {
	const classes = useStyles();

	const { user } = useContext(AuthContext);
	var userQueueIds = [];

	if (user.queues && user.queues.length > 0) {
		userQueueIds = user.queues.map(q => q.id);
	}

	const GetTickets = (status, showAll, withUnreadMessages) => {

		const { count } = useTickets({
			status: status,
			showAll: showAll,
			withUnreadMessages: withUnreadMessages,
			queueIds: JSON.stringify(userQueueIds)
		});
		// console.log(count)
		return count;
	}

	return (
		<div>
		<Container maxWidth="lg" className={classes.container}>
		<Grid container spacing={3}>
		<Grid item xs={3}>
		<Paper elevation={8} className="text-center" style={{ overflow: "hidden" }}>
<Typography component="h2" variant="h5" paragraph>
	<SupportAgentTwoToneIcon color="success" fontSize="large"/> Em Atendimento
	</Typography>
	<Grid item>
	<Typography component="h1" variant="h4">
		{GetTickets("open", "true", "false")}
</Typography>
	</Grid>
	</Paper>
	</Grid>
	<Grid item xs={3}>
		<Paper elevation={8} className="text-center" style={{ overflow: "hidden" }}>
<Typography component="h2" variant="h5" paragraph style={{ size: "30px" }}>
<ScheduleTwoToneIcon color="warning" fontSize="large"/> Aguardando
		</Typography>
		<Grid item>
	<Typography component="h1" variant="h4">
		{GetTickets("pending", "true", "false")}
</Typography>
	</Grid>
	</Paper>
	</Grid>
	<Grid item xs={3}>
		<Paper elevation={8} className="text-center" style={{ overflow: "hidden" }}>
<Typography component="h2" variant="h5" paragraph>
	<ThumbUpTwoToneIcon color="error" fontSize="large"/> Encerrados
		</Typography>
		<Grid item>
	<Typography component="h1" variant="h4">
		{GetTickets("closed", "true", "false")}
</Typography>
	</Grid>
	</Paper>
	</Grid>
	<Grid item xs={3}>
		<Paper elevation={8} className="text-center" style={{ overflow: "hidden", align: "center" }}>
<Typography component="h2" variant="h5" paragraph>
	<PlaylistAddCheckTwoToneIcon color="info" fontSize="large"/> Todos
		</Typography>
		<Grid item>
	<Typography component="h1" variant="h4">
		{GetTickets("closed", "true", "false") + GetTickets("pending", "true", "false") + GetTickets("open", "true", "false")}
</Typography>
	</Grid>
	</Paper>
	</Grid>
	<Grid item xs={12}>
		<Paper elevation={8} className={classes.fixedHeightPaper}>
		<Chart />
		</Paper>
		</Grid>
		</Grid>
		</Container>
		</div>
);
};

export default Dashboard;
