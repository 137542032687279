// import { toast } from "react-toastify";
import { i18n } from "../translate/i18n";
import Swal from 'sweetalert2'

const toastSuccess = success => {
	// console.log(success);
	// const successMsg = success.response?.data?.message || success.response.data.error;
	const successMsg = success;
	if (successMsg) {
		if (i18n.exists(`backendSuccess.${successMsg}`)) {
			Swal.fire({
				title: 'Ok!',
				text: i18n.t(`backendSuccess.${successMsg}`),
				icon: 'success',
				showConfirmButton: false,
				timer: 3000
			})
		} else {
			Swal.fire({
				title: 'Ok!',
				text: successMsg,
				icon: 'success',
				showConfirmButton: false,
				timer: 3000
			})
		}
	} else {
		Swal.fire({
			title: 'Ops!',
			text: 'Um erro ocorreu!',
			icon: 'error',
			showConfirmButton: false,
			timer: 3000
		})
	}
};

export default toastSuccess;
